<template>
  <div class="project-header">
    <!--[START] Header Block -->
    <div class="row">
      <div class="col-10">
        <label class="eyebrow NexaText-Light">{{eyebrow}}</label>
        <h1 class="title Nexa-Bold">{{title}}</h1>
        <h2 class="subtitle NexaText-Regular">{{subtitle}}</h2>
      </div>
    </div>
    <!--[END] Header Block -->
  </div>
</template>
<script>
  export default {
    name: 'ProjectHeader',
      props: {
      title: String,
      subtitle: String,
      eyebrow: String
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "src/assets/scss/custom.scss";
  .project-header{
    padding-top: 30px;
    padding-bottom: 30px;
    .title, .subtitle, .eyebrow{
      display: block;
      font-weight: 500;
    }
    .eyebrow{
      font-size: 14px;
      color: #ccc;
      text-transform: uppercase;
      letter-spacing: 0.25px;
    }
    .title{
      font-size: 26px;
      text-transform: uppercase;
      letter-spacing: 0.25px;
    }
    .subtitle{
      font-size: 16px;
      color: #666;
      text-transform: uppercase;
      letter-spacing: 1.25px;
    }
  }
</style>
